import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import Fonts from './Font';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      desktop: 766
    }
  }
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': [
  //         Fonts.UbuntuBold,
  //         Fonts.UbuntuLight,
  //         Fonts.UbuntuLightItalic,
  //         Fonts.UbuntuMedium,
  //         Fonts.UbuntuMediumItalic
  //       ]
  //     }
  //   }
  // }
});

const predictorTheme = responsiveFontSizes(theme);

export default predictorTheme;
