import React from 'react';
import Footer from '../pages/Footer';
import Header from '../pages/Header';

const PredicterLayout = ({ children, isSurvey }) => {
  return (
    <>
      <Header />
      {children}
      <Footer isSurvey />
    </>
  );
};

export default PredicterLayout;
