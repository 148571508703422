import React from 'react';
import PredicterLayout from './PredicterLayout';
import ContentLayout from './ContentLayout';
import { Link } from 'react-router-dom';

function Insights() {
  return (
    <PredicterLayout>
      <ContentLayout title="Insights">
        <div className="supportTextCont">
          <p className="supportText1">
            The <i>Biggest Life Decisions Project</i> has already produced many
            interesting insights. You can read summaries of these insights
            below:{''}
          </p>
          <ul className="text">
            <li>
              <a
                className="supportText3"
                href="https://www.psychologytoday.com/au/blog/life-s-biggest-decisions/202101/what-are-lifes-biggest-decisions"
                target="_blank"
                rel="noopener noreferrer"
              >
                What are Life’s Biggest Decisions?
              </a>{' '}
            </li>
            <li>
              <a
                className="supportText3"
                href="https://www.psychologytoday.com/au/blog/life-s-biggest-decisions/202102/when-do-lifes-biggest-decisions-happen"
                target="_blank"
                rel="noopener noreferrer"
              >
                When do Life’s Biggest Decisions Happen?
              </a>{' '}
            </li>
            <li>
              <a
                className="supportText3"
                href="https://www.psychologytoday.com/au/blog/life-s-biggest-decisions/202102/how-make-good-big-decision"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to Make a Good “Big” Decision?
              </a>{' '}
            </li>
            <li>
              <a
                className="supportText3"
                href="https://www.psychologytoday.com/au/blog/life-s-biggest-decisions/202102/which-big-life-decisions-lead-long-term-happiness"
                target="_blank"
                rel="noopener noreferrer"
              >
                Which Big Life Decisions Lead to Long-term Happiness?
              </a>{' '}
            </li>
            <li>
              <a
                className="supportText3"
                href="https://www.psychologytoday.com/au/blog/life-s-biggest-decisions/202103/how-good-are-you-predicting-your-big-life-decisions"
                target="_blank"
                rel="noopener noreferrer"
              >
                How Good Are You at Predicting Your Big Life Decisions?
              </a>{' '}
            </li>
          </ul>
          <p className="supportText1">
            If you have questions that are not answered by these articles, then
            you can <Link to="allresults"> explore the data yourself </Link>or
            <Link to="contact"> ask me</Link> to investigate.
          </p>
        </div>
      </ContentLayout>
    </PredicterLayout>
  );
}

export default Insights;
