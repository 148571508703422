import React, { useState } from 'react';
import PredicterLayout from './PredicterLayout';
import ContentLayout from './ContentLayout';
import { FormControl, TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { subscribeNewsletter } from '../axios/user';
import { Link } from 'react-router-dom';
import * as notify from '../helpers/notify';

function Support() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submit = e => {
    e.preventDefault();
    if (!(name.length && email.length)) {
      notify.info('All fields are required.');
      return;
    }
    const emailValid = validateEmail(email);
    if (!emailValid) {
      notify.error('Please enter valid email.');
      return;
    }
    setError(false);
    const payload = { name: name, email: email };
    subscribeNewsletter(
      () => {
        setSuccess(true);
      },
      () => {},
      payload
    );
  };

  return (
    <PredicterLayout>
      <ContentLayout title="Newsletter">
        <div className="supportTextCont">
          <p className="supportText1">
            Want to learn how to make better big life decisions? Those who sign
            up for the newsletter will be contacted when major new features are
            added and new <Link to="insights">insights</Link> are published.
            You’ll also be the first to receive updates on the book that is
            being written based on this research, which is tentatively titled{' '}
            <i>
              “The Ten Biggest Decisions You’ll Ever Make (And What Science Says
              You Should Do)”
            </i>
            .
          </p>
          {success ? (
            <p>Thank you! Your have susbcribed for newsletter successfully.</p>
          ) : (
            <form className="contactForm" style={{ marginTop: '-10px' }}>
              <div className="news-letter-fields">
                <FormControl className="textBox-1">
                  <label>Name</label>
                  <TextField
                    value={name}
                    variant="outlined"
                    onChange={e => setName(e.target.value)}
                    id="name"
                    aria-describedby="my-helper-text"
                  />
                </FormControl>
                <FormControl className="textBox-1">
                  <label>Email</label>
                  <TextField
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    id="email"
                    aria-describedby="my-helper-text"
                    error={error}
                    variant="outlined"
                    helperText={error ? 'Invalid email.' : ''}
                  />
                </FormControl>
              </div>
              <FormControl>
                <div className="news-letter-subscribe">
                  <Button
                    onClick={submit}
                    variant="contained"
                    className="contactButton"
                    style={{ minWidth: 130 }}
                  >
                    Subscribe
                  </Button>
                </div>
              </FormControl>
            </form>
          )}
        </div>
      </ContentLayout>
    </PredicterLayout>
  );
}

export default Support;
