import Swal from 'sweetalert2';
export function info(msg) {
  Swal.fire({
    // title: 'Error!',
    text: msg,
    icon: 'info',
    confirmButtonText: 'Ok'
  });
}

export function warning(msg) {
  Swal.fire({
    // title: 'Error!',
    text: msg,
    icon: 'warning',
    confirmButtonText: 'Ok'
  });
}

export function success(msg) {
  Swal.fire({
    title: 'Success!',
    text: msg,
    icon: 'success',
    confirmButtonText: 'Ok'
  });
}

export function error(msg) {
  Swal.fire({
    title: 'Error!',
    text: msg,
    icon: 'error',
    confirmButtonText: 'Ok'
  });
}
