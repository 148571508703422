import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import './App.css';
import Insights from './components/Insights';
import Newsletter from './components/Newsletter';

const About = React.lazy(() => import('./components/About'));
const Support = React.lazy(() => import('./components/Support'));
const Privacy = React.lazy(() => import('./components/Privacy'));
const Contact = React.lazy(() => import('./components/Contact'));
const Ranking = React.lazy(() => import('./pages/results/Ranking'));
const AllRanking = React.lazy(() => import('./pages/results/AllRanking'));
const EnterCode = React.lazy(() => import('./pages/EnterCode'));
const DecisionsChart = React.lazy(() =>
  import('./pages/results/DecisionsChart')
);
const AllDecisionsChart = React.lazy(() =>
  import('./pages/results/AllDecisionsChart')
);
const Survey = React.lazy(() => import('./pages/Survey'));
const Home = React.lazy(() => import('./pages/Home'));

const Loader = () => (
  <div className="home-loader">
    <CircularProgress style={{ color: '#FFCB11' }} />
  </div>
);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Suspense fallback={<Loader />}>
      <div className="App">
        {loading && <Loader />}
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/results/:code">
              {' '}
              {/* 746022 */}
              <Ranking />
            </Route>
            <Route exact path="/allresults">
              <AllRanking />
            </Route>
            <Route exact path="/chart/:code">
              {' '}
              {/* 746022 */}
              <DecisionsChart />
            </Route>
            <Route exact path="/alldecisions">
              {' '}
              {/* 746022 */}
              <AllDecisionsChart />
            </Route>
            <Route exact path="/survey">
              <Survey />
            </Route>
            <Route exact path="/insights">
              <Insights />
            </Route>
            <Route exact path="/newsletter">
              <Newsletter />
            </Route>
            <Route exact path="/enterCode">
              <EnterCode />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/support">
              <Support />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
            </Route>
          </Switch>
        </Router>
      </div>
    </React.Suspense>
  );
}

export default App;
