import axios from 'axios';
import env from '../env';

export function storeUserData(data) {
  return axios
    .post(`${env.api}/storeUserData/`, data)
    .then(response => response.data);
}

export function storeUser(onSuccess, onError, data) {
  axios.post(`${env.api}/storeUser/`, data).then(response => {
    onSuccess(response.data);
  });
}

export function quickInsights(onSuccess, onError, data) {
  axios.post(`${env.api}/quickInsights/`, data).then(response => {
    onSuccess(response.data);
  });
}

export function userDecisions(onSuccess, onError, data) {
  axios.post(`${env.api}/userDecisions/`, data).then(response => {
    onSuccess(response.data);
  });
}

export function allUserDecisions(onSuccess, onError, data) {
  axios.post(`${env.api}/allUserDecisions/`, data).then(response => {
    onSuccess(response.data);
  });
}

export function userDecisionsContinue(onSuccess, onError, data) {
  axios
    .post(`${env.api}/userDecisionsContinue/`, data)
    .then(response => {
      onSuccess(response.data);
    })
    .catch(() => onError());
}

export function checkCode(onSuccess, onError, data) {
  axios.post(`${env.api}/checkCode/`, data).then(response => {
    onSuccess(response.data);
  });
}

export function saveShortSurvey(onSuccess, onError, data) {
  axios.post(`${env.api}/saveShortSurvey/`, data).then(response => {
    onSuccess(response.data);
  });
}

export function subscribeNewsletter(onSuccess, onError, data) {
  axios.post(`${env.api}/subscribe-newsletter/`, data).then(response => {
    onSuccess(response.data);
  });
}
