import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    padding: '20px',
    maxWidth: '766px',
    [theme.breakpoints.up('desktop')]: {
      height: '89vh',
      padding: '0 20px'
    },
    marginTop: '4.8vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left',
    margin: '0 auto',
    fontFamily: 'Ubuntu',
    marginBottom: props => (props.page === 'about' ? '2rem' : '0')
  },
  overFlow: {
    height: '100%',
    [theme.breakpoints.up('desktop')]: {
      marginBottom: props => (props.isMore ? 0 : '3.5rem')
    }
  },
  title: {
    color: '#FFCB11',
    textDecoration: 'solid',
    fontWeight: 'bold',
    lineHeight: 1.5,
    fontSize: '52px',
    margin: '0px',
    fontFamily: 'Ubuntu !important'
  },
  content: {
    margin: '1rem',
    width: '-webkit-fill-available'
  },
  policy: {
    margin: '5px auto 20px',
    color: '#636466',
    fontSize: '17px',
    fontFamily: 'Ubuntu-Light',
    cursor: 'pointer',
    textAlign: 'center',
    justifyContent: 'center'
  },
  divider: {
    width: '100%',
    background: '#fec70f',
    height: '7px'
  },
  surveyTerm: {
    marginBottom: props => props.isSurvey && '4rem',
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '7px !important'
    }
  }
}));

const ContentLayout = ({
  children,
  isOverflow = false,
  isMore = false,
  isSurvey = false,
  title,
  page
}) => {
  const classes = useStyles({ isMore, isSurvey, page });
  const history = useHistory();
  return (
    <div className={`${classes.wrapper} ${isOverflow && classes.overFlow}`}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.divider} />
      <div className={classes.content}>{children}</div>
      {isMore ? (
        ''
      ) : (
        <>
          <div className={classes.divider}></div>
          <div
            className={`${classes.policy} ${classes.surveyTerm}`}
            onClick={() => history.push('/privacy')}
          >
            Terms of Use & Privacy Policy
          </div>
        </>
      )}
    </div>
  );
};

export default ContentLayout;
