import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  page: {
    height: '7.50vh',
    [theme.breakpoints.up('desktop')]: {
      height: '6.26vh'
    }
  },
  links: {
    padding: '8px 30px',
    [theme.breakpoints.up('desktop')]: {
      padding: '0px 30px'
    }
  }
}));

export default function Footer({ isSurvey = false }) {
  const classes = useStyles();
  const navActive = {
    fontSize: '20px',
    color: '#ffffff',
    fontWeight: '700'
  };
  return (
    <div className={`${isSurvey && classes.page} footerPage`}>
      <div className={`footerLinks ${isSurvey && classes.links}`}>
        <NavLink
          to="/"
          activeStyle={navActive}
          isActive={(match, location) => {
            return location.pathname === '/' || location.pathname === '/home';
          }}
        >
          Home
        </NavLink>
        <span> | </span>
        <NavLink to="/insights" activeStyle={navActive}>
          Insights
        </NavLink>
        <span> | </span>
        <NavLink to="/newsletter" activeStyle={navActive}>
          Newsletter
        </NavLink>
        <span> | </span>
        <NavLink to="/about" activeStyle={navActive}>
          About
        </NavLink>
        <span> | </span>
        <NavLink to="/support" activeStyle={navActive}>
          Support
        </NavLink>
        <span> | </span>
        <NavLink to="/contact" activeStyle={navActive}>
          Contact
        </NavLink>
      </div>
    </div>
  );
}
