import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import * as serviceWorker from './serviceWorker';
import predictorTheme from './fonts/theme';
import { CssBaseline } from '@material-ui/core';

ReactDOM.render(
  <ThemeProvider theme={predictorTheme}>
    <CssBaseline></CssBaseline>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
